import { memo } from "react";

const SvgDi = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 80 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.35 36 40 10.35 65.65 36M20 60l20-20 20 20"
      stroke="currentColor"
      strokeWidth="16"
      strokeLinecap="square"
    />
    <path
      d="M19.865 70.62Q14.625 67 9.386 60.103M60.148 70.62Q65.382 67 70.615 60.117"
      stroke="currentColor"
      strokeLinecap="square"
    />
  </svg>
);

const Memo = memo(SvgDi);
export default Memo;
